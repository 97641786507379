
import Card from "@/components/base/card/Card"
import Modal from "@/components/base/modal/Modal"
import Button from "@/components/base/Button"
import {
    mdiChevronRight,
    mdiChevronLeft,
    mdiClockTimeTwoOutline,
} from "@mdi/js"

export default {
    name: "ArticleBlok",
    components: { Card, Modal, Button },
    icons: {
        mdiChevronRight,
        mdiChevronLeft,
        mdiClockTimeTwoOutline,
    },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        slug() {
            return `artikel-${this.blok._uid}`
        },
        linkToText() {
            return `/?${this.slug}`
        },
        showModal: {
            get() {
                return this.slug in this.$route.query
            },
            set() {
                if (this.slug in this.$route.query) {
                    this.$router.go(-1)
                }
            },
        },
    },
}
